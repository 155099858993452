const { io } = require('socket.io-client')

const socket = io('https://sapi.khelbhai.in', {
  transports: ['websocket']
})

socket.on('connect_error', () => {
  // revert to classic upgrade
  socket.io.opts.transports = ['polling', 'websocket']
})

export default socket
